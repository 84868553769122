<template>
  <data-list
    ref="dataList"
    class="content md"
    url="/admin/fs-black-list"
    entity-name="黑名单"
    add-btn="添加黑名单"
    hide-show-detail
    :action="action"
    :rules="rules"
    :columns="columns"
    :search-key-type="{name: 1}">
    <template v-slot:query="{form}">
      <a-form-model-item label="">
        <a-input v-model="form.name" placeholder="输入姓名查询"></a-input>
      </a-form-model-item>
    </template>
    <template v-slot:edit="{form}">
      <a-form-model-item label="姓名" prop="name">
        <a-input v-model="form.name" placeholder="请输入姓名"></a-input>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
    export default {
        name: "TabletBlacklist",
        data() {
            return {
                columns: [
                    {title: '名字', dataIndex: 'name'},
                ],
                rules: {
                    name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                }
            }
        },
        computed: {
            action() {
                return this.$route.meta.action;
            }
        },
    }
</script>

<style scoped>

</style>
